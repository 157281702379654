<template>
  <section
    class="invoice-preview-wrapper"
    style="position: relative"
  >
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="exit_voucher === undefined"
    >
      <h4 class="alert-heading">
        Error fetching invoice data
      </h4>
      <div class="alert-body">
        No invoice found with this invoice id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'facture-list' }"
        >
          invoice List
        </b-link>
        for other invoice.
      </div>
    </b-alert>

    <b-row
      v-if="exit_voucher"
      class="invoice-preview"
    >
      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-card
          v-for="exit_voucher in allOrders"
          :id="exit_voucher.id"
          :key="exit_voucher.id"
          no-body
          class="invoice-preview-card pagebreak"
        >
          <!-- Header -->
          <b-card-body class="page-break">
            <b-row
              class="invoice-padding"
              style="
                border: 1px solid black;
                border-radius: 18px;
                padding: 10px;
              "
            >
              <!-- Header: Left Content -->

              <b-col
                cols="12"
                md="12"
                style="color: black; font-size: 18px; text-align: center"
              >
                <img
                  src="@/assets/images/logo/logo.png"
                  width="150px"
                  height="auto"
                >
                <div
                  style="color: black; font-size: 28px"
                  class="mt-1 font-weight-bolder"
                >
                  GROUPES SERVICES RAPIDES <br>
                  <span
                    style="font-size: 24px"
                  >VENTE TOUT GENRE DE CLEFS BRUT, MACHINES ET
                    ACCESSOIRES</span>
                </div>

                <b-card-text
                  style="font-size: 16px"
                  class="font-weight-bold mt-1"
                >
                  <strong> RUE EL KOUNAITRA 1-dar Chaabene El Fehri</strong><br>
                  <strong>Tel:+216 97 607 740</strong>
                  <br>
                  <strong>Email:gsr-cle1@hotmail.fr</strong>
                  <br>
                  <strong>MF : 1082793 F/A/M/000 | C.D 837979P</strong>
                </b-card-text>
              </b-col>

              <!-- Header: Right Content -->
              <!-- Header: Right Content -->
            </b-row>
          </b-card-body>
          <b-card-body>
            <b-row style="color: black">
              <b-col
                md="8"
                sm="12"
              >
                <h3
                  class="font-weight-bolder"
                  style="
                    color: black;
                    font-size: 25px;
                    border: 1px solid black;
                    border-radius: 18px;
                    padding: 10px;
                    text-align: center;
                  "
                >
                  * BON DE SORTIE *
                </h3>
                <div class="mt-1 d-flex">
                  <table style="border: 1px solid black">
                    <tr
                      style="
                        color: black;
                        font-size: 16px;
                        border: 1px solid black;
                      "
                    >
                      <th style="text-align: center">
                        N° du pièce
                      </th>
                    </tr>
                    <tr>
                      <td
                        v-if="exit_voucher"
                        style="text-align: center"
                      >
                        {{ exit_voucher.id }}
                      </td>
                    </tr>
                  </table>
                  <table style="border: 1px solid black; margin-left: 10px">
                    <tr
                      style="
                        color: black;
                        font-size: 16px;
                        border: 1px solid black;
                      "
                    >
                      <th style="text-align: center">
                        Date
                      </th>
                    </tr>
                    <tr>
                      <td
                        v-if="exit_voucher"
                        style="text-align: center"
                      >
                        {{ format(exit_voucher.date) }}
                      </td>
                    </tr>
                  </table>
                </div>
              </b-col>
              <b-col
                v-if="exit_voucher.driver"
                md="4"
                sm="12"
                style="
                  border: 1px solid black;
                  border-radius: 18px;
                  font-size: 14px;
                  padding: 10px;
                "
              >
                <!-- Header: Left Content -->
                <div style="color: black; font-size: 18px">
                  <h4
                    style="color: black; font-size: 20px"
                    class="mb-1 font-weight-bolder"
                  >
                    {{ exit_voucher.driver.name }}
                  </h4>
                  <span class="mb-0 font-weight-bold">
                    <strong>Camion : </strong>
                    {{ exit_voucher.truck.serial_number }}
                  </span>
                  <b-card-text class="mb-0 font-weight-bold">
                    <strong>Validité :</strong> Du
                    {{ format(exit_voucher.date_start) }} Au
                    {{ format(exit_voucher.date_end) }}
                  </b-card-text>
                  <b-card-text class="mb-0 font-weight-bold">
                    <strong>Secteurs :</strong>
                    <span
                      v-for="(item, index) in exit_voucher.states"
                      :key="index"
                    >
                      {{ item.name }} /
                    </span>
                  </b-card-text>
                </div>

                <!-- Header: Right Content -->
                <!-- Header: Right Content -->
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Invoice Description: Table -->
          <b-card-body>
            <b-table-lite
              :bordered="true"
              responsive
              :items="exit_voucher.p"
              :fields="[
                'N°',
                'Réf',
                'Désignation',
                'Qté',
                'Prix U.H.T',
                'Total H.T',
                'Taux TVA',
                'Prix U.TTC',
                'Total TTC',
              ]"
              class="ta"
            />
          </b-card-body>
          <!-- Invoice Description: Total -->

          <b-row class="card-body">
            <b-col
              cols="4"
              md="5"
              xl="5"
              sm="12"
              style="
                border: 1px solid black;
                border-radius: 18px;
                padding: 10px;
              "
            >
              <span
                class="font-weight-bolder mt-1 h4"
                style="color: black; border-bottom: 1px solid black"
              >
                Arrêté le présent bon de sortie a la somme de:
              </span>
              <br><br>
              <span
                class="font-weight-bold mt-1 h4"
                style="color: black"
              >
                {{ exit_voucher.lettre }}
              </span>
              <!-- <div v-html="order.note" /> -->
            </b-col>

            <b-col
              cols="4"
              md="3"
              xl="3"
              sm="12"
            >
              <!-- <table
                v-if="order"
                style="padding: 5px;"
              >
                <tr style="color:black;font-size:16px">
                  <th>Base</th>
                  <th>Taux</th>
                  <th>Montant</th>
                </tr>
                <tr>

                  <td v-if="order">
                    {{ order.price_ht }}
                  </td>
                  <td v-else>
                    0
                  </td>
                  <td>19,0</td>
                  <td v-if="order">
                    {{ order.tax }}
                  </td>
                  <td v-else>
                    0
                  </td>
                </tr>
              </table> -->
            </b-col>

            <b-col
              cols="4"
              md="4"
              xl="4"
              sm="12"
              class="p-0"
              style="border: 1px solid black; border-radius: 18px"
            >
              <table>
                <tbody>
                  <tr class="trborder">
                    <th scope="row">
                      Total H.Tva :
                    </th>
                    <td>
                      {{ exit_voucher.price_ht }}
                    </td>
                  </tr>
                  <tr class="trborder">
                    <th scope="row">
                      Total Tva :
                    </th>
                    <td>{{ exit_voucher.tax }}</td>
                  </tr>

                  <tr>
                    <th scope="row">
                      Total TTC :
                    </th>
                    <td>{{ exit_voucher.price_ttc }}</td>
                  </tr>
                </tbody>
              </table>
            </b-col>
          </b-row>
          <b-row class="card-body text-center">
            <b-col
              cols="6"
              md="6"
              xl="6"
              sm="12"
            >
              <h5 style="color: black; font-size: 16px">
                Signature
              </h5>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions"
      >
        <b-card>
          <!-- Button: Print -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            @click="printInvoice"
          >
            Print
          </b-button>

          <!-- Button: Edit -->
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import axiosIns from '@/libs/axios'

import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BTableLite,
  BCardText,
  BButton,
  BAlert,
  BLink,
  VBToggle,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import { NumberToLetter } from 'convertir-nombre-lettre'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
  },
  props: ['all'],
  data() {
    return {
      allOrders: [],
      p: [],
      exit_voucher: [],
      lettre: '',
    }
  },

  created() {
    this.id = this.$route.params.id
    this.getOrders()
    // this.getProducts()
  },

  methods: {
    format(date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },

    async getOrders() {
      for (let k = 0; k < this.all.length; k += 1) {
        // eslint-disable-next-line no-await-in-loop
        await axiosIns
          .get(`/exit-vouchers/details/${this.all[k]}/`)
          .then(response => {
            this.exit_voucher = response.data
          })
        if (this.exit_voucher != null) {
          const sommeString = this.exit_voucher.price_ttc.toString()
          if (sommeString.includes('.000') === true) {
            this.exit_voucher.lettre = `${NumberToLetter(
              this.exit_voucher.price_ttc,
            )} Dinars `
          } else {
            const [dinar, milim] = this.order.price_ttc.split('.')
            this.exit_voucher.lettre = `${NumberToLetter(
              dinar,
            )} Dinars , ${NumberToLetter(milim)} `
          }
          // eslint-disable-next-line no-await-in-loop
          const { data } = await axiosIns.post(
            '/exit-vouchers-products/details/',
            { exit_voucher: this.exit_voucher.id },
          )
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < data.length; i++) {
            this.p.push({
              Réf: data[i].reference,
              Désignation: data[i].label,
              Qté: data[i].quantity,
              'Prix U.H.T': data[i].price_ht,
              'Total H.T': data[i].total_ht,
              'Taux TVA': data[i].vat,
              'Prix U.TTC': data[i].price_ttc,
              'Total TTC': data[i].total_ttc,
              'N°': i + 1,
            })
            this.exit_voucher.p = this.p
          }
          this.p = []
        }
        this.allOrders.push(this.exit_voucher)
      }
    },

    // eslint-disable-next-line camelcase
    addnewitem(
      nb,
      reference,
      label,
      quantity,
      price_ht,
      total_ht,
      tax,
      price_ttc,
      total_ttc,
    ) {
      this.p.push({
        Réf: reference,
        Désignation: label,
        Qté: quantity,
        'Prix U.H.T': price_ht,
        'Total H.T': total_ht,
        'Taux TVA': tax,
        'Prix U.TTC': price_ttc,
        'Total TTC': total_ttc,
        'N°': nb,
      })
    },
  },
  setup() {
    const printInvoice = () => {
      window.print()
    }

    return {
      printInvoice,
    }
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.ta {
  white-space: nowrap;
  font-size: 18px;
  color: black;
}
th {
  padding: 10px;
  text-align: left;
}
.trborder {
  border-bottom: 1px solid black;
}

table {
  font-size: 18px;
  width: 100%;
}

.contact {
  background-color: #0b6746;
  padding: 10px;
  border-radius: 20px;
}
</style>

<style lang="scss">
@media print {
  .page-break {
    page-break-before: always;
  }
  div{
    page-break-before: always;
  }

  .ta {
    white-space: nowrap;
    overflow-x: visible;
    font-size: 12px;
    color: black;
  }
  footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: auto; /* Footer height */
  }

  .table thead th {
    border-bottom: 2px solid black;
  }

  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }

  .contact {
    background-color: #0b6746;
    padding: 20px;
    border-radius: 25px;
    -webkit-print-color-adjust: exact;
  }
  footer footer-light footer-static {
    display: inline-block;
  }
  footer.footer {
    display: none;
  }

  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
  .tdt {
    padding: 10px;
    text-align: left;
    border: 1px solid black;
  }
  .tht {
    padding: 10px;
    text-align: left;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }
}
</style>
